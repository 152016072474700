import {PaletteOptions} from "@mui/material";
import {blue, common, green, grey, orange, red} from "@mui/material/colors";
import {SimplePaletteColorOptions} from "@mui/material/styles/createPalette";

export interface ColorsOptions {
    primary: SimplePaletteColorOptions,
    secondary: SimplePaletteColorOptions
}

export const appColors: ColorsOptions = {
    primary: {
        light: blue[400],
        main: blue[600],
        dark: blue[900],
    },
    secondary: {
        light: grey[100],
        main: grey[300],
        dark: grey[500]
    }
}

export const palette: PaletteOptions = {
    primary: {
        contrastText: common.white,
        dark: appColors.primary.dark,
        main: appColors.primary.main,
        light: appColors.primary.light
    },
    secondary: {
        contrastText: common.black,
        dark: appColors.secondary.dark,
        main: appColors.secondary.main,
        light: appColors.secondary.light
    },
    success: {
        contrastText: common.white,
        dark: green[900],
        main: green[600],
        light: green[400]
    },
    info: {
        contrastText: common.white,
        dark: appColors.primary.dark,
        main: appColors.primary.main,
        light: appColors.primary.light
    },
    warning: {
        contrastText: common.white,
        dark: orange[900],
        main: orange[600],
        light: orange[400]
    },
    error: {
        contrastText: common.white,
        dark: red[900],
        main: red[600],
        light: red[400]
    },
    text: {
        primary: common.black,
        secondary: appColors.secondary.dark,
    },
    background: {
        default: appColors.secondary.light,
        paper: common.white
    },
    divider: grey[200]
};
