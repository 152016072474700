import {TypographyOptions} from "@mui/material/styles/createTypography";
import {palette} from "./palette";


export const typography: TypographyOptions = {
    h1: {
        color: palette.text?.primary,
        fontWeight: 700,
        fontSize: '35px',
        letterSpacing: 0,
        lineHeight: '35px'
    },
    h2: {
        color: palette.text?.primary,
        fontWeight: 600,
        fontSize: '28px',
        letterSpacing: 'normal',
        lineHeight: 1.14
    },
    h3: {
        color: palette.text?.primary,
        fontWeight: 700,
        fontSize: '24px',
        letterSpacing: 'normal',
        lineHeight: 1
    },
    h4: {
        color: palette.text?.primary,
        fontWeight: 700,
        fontSize: '20px',
        letterSpacing: 'normal',
        lineHeight: 1
    },
    h5: {
        color: palette.text?.primary,
        fontWeight: 700,
        fontSize: '16px',
        letterSpacing: 'normal',
        lineHeight: 1.5
    },
    h6: {
        color: palette.text?.primary,
        fontWeight: 700,
        fontSize: '14px',
        letterSpacing: 'normal',
        lineHeight: 1.5
    },
    subtitle1: {
        color: palette.text?.primary,
        fontSize: '16px',
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: 1.5
    },
    subtitle2: {
        color: palette.text?.primary,
        fontWeight: 600,
        fontSize: '14px',
        letterSpacing: 0,
        lineHeight: '16px'
    },
    body1: {
        color: palette.text?.primary,
        fontWeight: 400,
        fontSize: '16px',
        letterSpacing: 0,
        lineHeight: 1.5
    },
    body2: {
        color: palette.text?.primary,
        fontWeight: 400,
        fontSize: '14px',
        letterSpacing: 0,
        lineHeight: 1.5
    },
    button: {
        color: palette.text?.primary,
        fontSize: '14px'
    },
    caption: {
        color: palette.text?.secondary,
        fontSize: '11px',
        letterSpacing: '0.33px',
        lineHeight: '13px'
    }
};

