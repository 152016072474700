import {Components} from "@mui/material";
import {appColors} from "./palette";
import {common} from "@mui/material/colors";

export const components: Components = {
    MuiButton: {
        styleOverrides: {
            root: {
                textTransform: 'none',
                borderRadius: 0
            }
        }
    },
    MuiTab: {
        styleOverrides: {
            root: {
                textTransform: 'none',
                color: common.black,
                fontWeight: 'bold',
                height: '64px',
                '&.Mui-selected': {
                    backgroundColor: appColors.secondary.light,
                    color: common.black,
                }
            }
        }
    },
    MuiTabs: {
        styleOverrides: {
            indicator: {
                height: '3px',
            }
        }
    },
    MuiListItem: {
        styleOverrides: {
            root: {
                '&.Mui-selected': {
                    backgroundColor: appColors.secondary.light,
                    '&:hover': {
                        backgroundColor: appColors.secondary.light,
                    }
                }
            },
        }
    }
};
